import { AudioPlayer } from "@/components/AudioPlayer";
import { useAuth } from "@/context/AuthProvider";
import { useCustomer } from "@/context/CustomerProvider";
import PlayerProvider from "@/context/PlayerProvider";
import useFetch from "@/hooks/useFetch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiUrl } from "@/../config";

export default function Page08() {
  const { profile } = useAuth();
  const { customerInfo } = useCustomer();
  const { t } = useTranslation();
  const intro = localStorage.getItem('language') == 'he' ? profile?.audioHE : profile?.audioEN

  const { data: meditation } = useFetch<{ data: { audio: string } }>(
    `${apiUrl}/api/v1/meditations/random?gender=${customerInfo.gender?.toUpperCase()}&tags=${
      customerInfo.tag
    }&lang=${localStorage.getItem("language")}`
  );

  const [playlist, setPlaylist] = useState([
    { src: intro , title: "הקדמה" },
  ]);

  const getRandomMeditation = async () => {
    const response = await fetch(
      `${apiUrl}/api/v1/meditations/random?gender=${customerInfo.gender?.toUpperCase()}&lang=${localStorage.getItem(
        "language"
      )}`,
      {
        credentials: "include",
      }
    );
    const json = await response.json();
    setPlaylist([playlist[0], { src: json.data.audio, title: "מדיטציה" }]);
  };

  useEffect(() => {
    if (meditation?.data.audio) {
      setPlaylist([
        ...playlist,
        { src: meditation?.data.audio || "", title: "מדיטציה" },
      ]);
    }
  }, [meditation]);

  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-4xl mb-8">{t("page_8.tx_1")}</h1>
      <PlayerProvider playlist={playlist}>
        <AudioPlayer />
        <button onClick={getRandomMeditation} className="hover:underline">
          {t("page_8.tx_2")}
        </button>
      </PlayerProvider>
    </section>
  );
}
