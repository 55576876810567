import { RatingForm } from "@/components/RatingForm";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './FBComponent.css';
import FacebookShareButton from './FBComponent';
import { useAuth } from "@/context/AuthProvider";

export default function Page11() {
  const { profile } = useAuth();
  const { t } = useTranslation();
  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-4xl mb-4">{t("page_11.tx_1")}</h1>
      <div>
        <RatingForm />
      </div>
      {profile?.share ? (
      <FacebookShareButton />
      ) : <></>}
    </section>
  );
}
