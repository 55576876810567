import createCtx from "@/hooks/createCtx";
import useLocalStorage from "@/hooks/useLocalStorage";
import { PropsWithChildren } from "react";

type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

type MoodRange = IntRange<1, 6>;
type Gender = "female" | "male" | null;

interface CustomerInfo {
  gender: Gender;
  experience: boolean;
  preMood: MoodRange;
  postMood: MoodRange;
  tag: string;
  rate: number;
  started: boolean;
  finished: boolean;
  educationTag: string;
}

interface CustomerContext {
  customerInfo: CustomerInfo;
  changeCustomerInfo: (update: Partial<CustomerInfo>) => void;
  resetCustomerInfo: () => void;
}

interface CustomerProviderProps extends PropsWithChildren {}

export const [useCustomer, Customer] = createCtx<CustomerContext>();

const initialValue: CustomerInfo = {
  gender: null,
  experience: false,
  preMood: 1,
  postMood: 1,
  tag: "",
  rate: 0,
  started: false,
  finished: false,
  educationTag: ''
};

export default function CustomerProvider({ children }: CustomerProviderProps) {
  const [customerInfo, setCustomerInfo] = useLocalStorage<CustomerInfo>(
    "customer-info",
    initialValue
  );

  const changeCustomerInfo = (update: Partial<CustomerInfo>) => {
    setCustomerInfo({ ...customerInfo, ...update });
  };

  const resetCustomerInfo = () => {
    setCustomerInfo(initialValue);
  };

  return (
    <Customer value={{ customerInfo, changeCustomerInfo, resetCustomerInfo }}>{children}</Customer>
  );
}
