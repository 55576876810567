import { Button } from "@/components/Button";
import { LanguageSwitcher } from "@/components/LanguageSwitcher";
import { useAuth } from "@/context/AuthProvider";
import { FormEvent, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { loginUser, isAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termCheckbox, setTermCheckbox] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  if (localStorage.getItem("language") == null) {
    localStorage.setItem("language", "he");
    localStorage.setItem("i18nextLng", "he");
  }
  const handleClick = () => {
    setTermCheckbox(!termCheckbox);
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    const result = await loginUser(email, password);
    if (!result.ok) {
      setError("שם משתמש או סיסמה אינם נכונים");
    } else {
      navigate("/page-01", { replace: true });
    }
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/page-01", { replace: true });
    }
  }, [isAuth]);

  useEffect(() => {
    const urlPath = window.location.pathname;
    const usernameFromURL = urlPath.split("/").pop();

    if (usernameFromURL && usernameFromURL !== "login") {
      setEmail(usernameFromURL);
    }
  }, []);

  return (
    <main
      className="h-full w-full flex-grow flex flex-col place-items-center items-center"
      dir={i18n.dir()}
    >
      <LanguageSwitcher dir={i18n.dir()} />
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col max-w-xs gap-4 mt-40"
      >
        <h1 className="text-5xl sm:text-6xl text-center">Chilax</h1>
        {error ? <p className="text-red-500">{error}</p> : null}
        <label className="flex flex-col">
          <span className="sr-only">כתובת דוא"ל</span>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="rounded-full border border-primary bg-input px-2.5 py-1 placeholder:text-gray-500"
            // type="email"
            name="user-email"
            id="user-email"
            placeholder={t("login.tx_1") || ""}
          />
        </label>
        <label className="flex flex-col">
          <span className="sr-only">סיסמה</span>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="rounded-full border border-primary bg-input px-2.5 py-1 placeholder:text-gray-500"
            type="password"
            name="user-password"
            id="user-password"
            placeholder={t("login.tx_2") || ""}
          />
        </label>
        <div className="flex items-center">
          <input
            type="checkbox"
            onClick={handleClick}
            checked={termCheckbox}
            className={
              "cursor-pointer w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            }
          />
          <span className="mr-2 ml-2">
            {t("login.tx_6") || ""}
            <a href="https://chilax.club/term-of-use/">
              <span className="text-blue-600 visited:text-purple-600"> {t("login.tx_7") || ""}</span>
            </a>
          </span>
        </div>
        <Button
          disabled={!termCheckbox}
          className={termCheckbox ? "bg-primary text-white" : ""}
        >
          {t("login.tx_3")}
        </Button>

        {/* informational text */}

        {/* <div
          role="alert"
          className="relative flex w-full px-2 py-2 text-base text-gray-900 rounded-full font-regular bg-red-400/50 justify-center"
        >
          <div>
        <span className="text-sm">Agree to the site rules</span>
        </div>
        </div> */}

        <div /* className="text-center" */>
          <p className="mb-4">{t("login.tx_4")}</p>
          <span className="italic">{t("login.tx_5")}</span>
        </div>
      </form>
      <img
        id={i18n.language == "he" ? "footerLoginImgHe" : "footerLoginImgEn"}
        // className={pathname === "/page-01" ? "w-full max-w-xs" : "h-full"}
        src={`/img/page-08.png`}
      />
      <img
        id="headerLoginImg"
        // className={pathname === "/page-01" ? "w-full max-w-xs" : "h-full"}
        src={`/img/logo.png`}
      />
    </main>
  );
}
