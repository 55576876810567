import {usePlayer} from "@/context/PlayerProvider";
import styles from "./AudioPlayer.module.css";
import {useTranslation} from "react-i18next";

export default function AudioPlayer() {
    const {t, i18n} = useTranslation();
    const {
        isPlaying,
        togglePlayPause,
        audioPlayer,
        progressBar,
        playlist,
        currentIndex,
        handleLoadedMetadata,
        handleEnded,
        changeRange,
    } = usePlayer();
    return (
        <div
            className="bg-player flex flex-col justify-center items-center p-6 bg-center bg-contain bg-no-repeat gap-6">
            <span>{t('audio.tx_1')}: {playlist[currentIndex].title}</span>
            <audio
                onLoadedMetadata={handleLoadedMetadata}
                onEnded={handleEnded}
                src={playlist[currentIndex].src}
                ref={audioPlayer}
                hidden
            />
            <input
                className={styles.player}
                defaultValue={0}
                onChange={changeRange}
                dir="ltr"
                ref={progressBar}
                type="range"
            />
            <button onClick={() => togglePlayPause()}>
                <span className="sr-only">{t('audio.tx_2')} {isPlaying ? t('audio.tx_3') : t('audio.tx_4')}</span>
                <img src={`/img/${isPlaying ? "pause" : "play"}-button.svg`} alt=""/>
            </button>
        </div>
    );
}
