import createCtx from "@/hooks/createCtx";
import {PropsWithChildren, useEffect, useState} from "react";
import {apiUrl} from '@/../config'

interface Profile {
    _id: string;
    name: string;
    email: string;
    phone: string;
    address: string;
    payment: string;
    form: boolean;
    role: "ADMIN" | "USER" | "DEMO";
    image: string;
    audio: string;
    audioHE: string;
    audioEN: string;
    share: boolean;
    imageFB: string;
    imageInst: string;
    educationSection: boolean;
}

interface AuthContext {
    profile: Profile | undefined;
    isAuth: boolean | null;
    loginUser: (email: string, password: string) => Promise<any>;
    logoutUser: () => Promise<any>;
}

export const [useAuth, Auth] = createCtx<AuthContext>();

interface AuthProviderProps extends PropsWithChildren {
}

export default function AuthProvider({children}: AuthProviderProps) {
    const [profile, setProfile] = useState<Profile | undefined>(undefined);
    const [isAuth, setIsAuth] = useState<boolean | null>(null);

    const loginUser = async (email: string, password: string) => {
        const response = await fetch(
            `${apiUrl}/api/v1/auth/login`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                credentials: "include",
                body: JSON.stringify({email, password}),
            }
        );
        const json = await response.json();
        if (response.status === 200 && json.ok) {
            setIsAuth(true);
            return json;
        } else {
            setIsAuth(false);
            setProfile(undefined);
            return json;
        }
    };

    const logoutUser = async () => {
        const response = await fetch(
            `${apiUrl}/api/v1/auth/logout`,
            {
                credentials: "include",
                method: "POST",
            }
        );
        const json = await response.json();
        setIsAuth(false);
        setProfile(undefined);
        return false
    };

    const getProfile = async () => {
        const response = await fetch(
            `${apiUrl}/api/v1/auth/profile`,
            {
                method: "POST",
                credentials: "include",
            }
        );
        const json = await response.json();
        if (response.status === 200 && json.ok) {
            setProfile(json.data);
            setIsAuth(true);
            return {ok: json.ok};
        } else {
            setIsAuth(false);
            setProfile(undefined);
        }
    };

    useEffect(() => {
        getProfile();
    }, [isAuth]);

    const values = {
        profile,
        isAuth,
        loginUser,
        logoutUser,
    };

    return <Auth value={values}>{children}</Auth>;
}
