import { useCustomer } from "@/context/CustomerProvider";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Page07() {
  const { t, i18n } = useTranslation();
  const { changeCustomerInfo, customerInfo } = useCustomer();
  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-4xl mb-8">{t("page_7.tx_1")}</h1>
      <div className="grid grid-cols-2 max-w-sm gap-4 mx-auto">
        <Link to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "relaxation" })}
            className="flex items-center gap-2"
            aria-labelledby="sea"
          >
            <img
              src={`/img/radio-${
                customerInfo.tag === "relaxation" ? "full" : "empty"
              }.svg`}
              alt="כפתור רדיו"
            />
            <span id="">{t("page_7.tx_2")}</span>
          </button>
        </Link>
        {i18n.language === "he" && (
          <Link to="/page-08">
            <button
              onClick={() => changeCustomerInfo({ tag: "positivity" })}
              className="flex items-center gap-2"
              aria-labelledby="desert"
            >
              <img
                src={`/img/radio-${
                  customerInfo.tag === "positivity" ? "full" : "empty"
                }.svg`}
                alt="כפתור רדיו"
              />
              <span id="">{t("page_7.tx_3")}</span>
            </button>
          </Link>
        )}
        <Link to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "positivity" })}
            className="flex items-center gap-2"
            aria-labelledby="airbaloon"
          >
            <img
              src={`/img/radio-${
                customerInfo.tag === "positivity" ? "full" : "empty"
              }.svg`}
              alt="כפתור רדיו"
            />
            <span id="">{t("page_7.tx_4")}</span>
          </button>
        </Link>
        {i18n.language === "he" && (
          <Link to="/page-08">
            <button
              onClick={() => changeCustomerInfo({ tag: "immunity" })}
              className="flex items-center gap-2"
              aria-labelledby="ski"
            >
              <img
                src={`/img/radio-${
                  customerInfo.tag === "immunity" ? "full" : "empty"
                }.svg`}
                alt="כפתור רדיו"
              />
              <span id="">{t("page_7.tx_5")}</span>
            </button>
          </Link>
        )}
        <Link to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "music" })}
            className="flex items-center gap-2"
            aria-labelledby="airbaloon"
          >
            <img
              src={`/img/radio-${
                customerInfo.tag === "music" ? "full" : "empty"
              }.svg`}
              alt="כפתור רדיו"
            />
            <span id="">{t("page_7.tx_6")}</span>
          </button>
        </Link>
        <Link to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "short" })}
            className="flex items-center gap-2"
            aria-labelledby="ski"
          >
            <img
              src={`/img/radio-${
                customerInfo.tag === "short" ? "full" : "empty"
              }.svg`}
              alt="כפתור רדיו"
            />
            <span id="">{t("page_7.tx_7")}</span>
          </button>
        </Link>
      </div>
    </section>
  );
}
