import { useAuth } from "@/context/AuthProvider";
import { useCustomer } from "@/context/CustomerProvider";
import { useTranslation } from "react-i18next";
import { apiUrl } from "@/../config";

export default function RatingForm() {
  const { t } = useTranslation();
  const ranks = [t('rating.unlike'), t('rating.fine'), t('rating.like'), t('rating.much_like')];
  const { changeCustomerInfo, customerInfo } = useCustomer();
  const { profile } = useAuth();


  return (
    <ul aria-label="טופס דירוג" className="flex gap-2">
      {Array.from(Array(4).keys()).map((rate) => (
        <li key={rate}>
          <button
            onClick={() => {
              changeCustomerInfo({ rate: rate + 1 })
              if (rate == 2 || rate == 3) {
                fetch(`${apiUrl}/api/v1/statistics/` + profile?._id, {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  credentials: "include",
                  body: JSON.stringify({ statName: "likes" }),
                });
              }
            }}
            aria-label={`דירוג ${rate + 1}`}
          >
            <img
              src={
                customerInfo.rate < rate + 1
                  ? "/img/empty-star.svg"
                  : "/img/full-star.svg"
              }
              alt="כוכב דירוג"
            />
            <span>{ranks[rate]}</span>
          </button>
        </li>
      ))}
    </ul>
  );
}
