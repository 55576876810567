import { useCustomer } from "@/context/CustomerProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Epage01() {
  const { t } = useTranslation();
  const { changeCustomerInfo } = useCustomer();
  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-4xl mb-8">{t("epage_01.tx_1")}</h1>
      <div className="grid grid-cols-2 max-w-xs gap-4 mx-auto">
        <Link to="/epage-02">
          <button
            onClick={() => changeCustomerInfo({ educationTag: "motivation" })}
            aria-labelledby="education"
          >
            <img src="/img/img_motivation.png" alt={t("epage_01.tx_2") as string} />
            <span id="education">{t("epage_01.tx_2")}</span>
          </button>
        </Link>
        <Link to="/epage-02">
          <button
            onClick={() => changeCustomerInfo({ educationTag: "about" })}
            aria-labelledby="relaxation"
          >
            <img src="/img/img_about_the_app.png" alt={t("epage_01.tx_3") as string} />
            <span id="relaxation">{t("epage_01.tx_3")}</span>
          </button>
        </Link>
        <Link to="/epage-02">
          <button
            onClick={() => changeCustomerInfo({ educationTag: "stressRelief" })}
            aria-labelledby="relaxation"
          >
            <img src="/img/img_stress_relief.png" alt={t("epage_01.tx_4") as string} />
            <span id="relaxation">{t("epage_01.tx_4")}</span>
          </button>
        </Link>
        <Link to="/epage-02">
          <button
            onClick={() => changeCustomerInfo({ educationTag: "winningTips" })}
            aria-labelledby="relaxation"
          >
            <img src="/img/img_winning_tips.png" alt={t("page_01.tx_5") as string} />
            <span id="relaxation">{t("epage_01.tx_5")}</span>
          </button>
        </Link>
      </div>
    </section>
  );
}