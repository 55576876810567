import { useAuth } from "@/context/AuthProvider";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Menu from "./Menu";

export default function Header() {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // @ts-ignore
    i18n.changeLanguage(localStorage.getItem("language"));
  }, []);

  const { profile } = useAuth();

  // @ts-ignore
  return (
    <header className="flex justify-between items-center h-24 sm:h-28 py-2">
      <img
        id="companyLogo"
        className="h-full"
        style={{maxWidth: '148px'}}
        src={profile?.image}
        alt={t("header.tx_1") as string}
      />
      <div className="h-full flex flex-col gap-4 items-center">
      <img className="h-full" src="/img/chilax-logo-text.svg" alt=""  id="appLogo" style={{width: '100%', maxWidth: '100px', height: 'auto'}} />
        <div className="relative" role="navigation">
          <span className="sr-only">{t("header.tx_2")}</span>
          <button
            onClick={() => setMenuOpen((prev) => !prev)}
            className="text-primary border border-primary hover:bg-primary hover:text-white focus:ring-4 focus:outline-none focus:ring-secondary/50 font-medium rounded-lg text-sm p-1.5 text-center inline-flex items-center ml-2"
          >
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            <span className="sr-only">{t("header.tx_3")}</span>
          </button>
          {/* @ts-ignore */}
          {menuOpen ? <Menu setMenuOpen={setMenuOpen} /> : null}
        </div>
      </div>
    </header>
  );
}
