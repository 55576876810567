import { useCustomer } from "@/context/CustomerProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Page02_1() {
  const { changeCustomerInfo } = useCustomer();
  const { t, i18n } = useTranslation();
  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-4xl mb-8">{t("page_02_1.tx_1")}</h1>
      <div className="grid grid-cols-2 max-w-xs gap-4 mx-auto">
        {i18n.language == "en" ? (
          <><Link to="/epage-01">
            <button
              // onClick={() => changeCustomerInfo({ tag: "sea" })}
              aria-labelledby="education"
            >
              <img src="/img/img_education.png" alt={t("page_02_1.tx_2") as string} />
              <span id="education">{t("page_02_1.tx_2")}</span>
            </button>
          </Link>
          <Link to="/page-03">
              <button
                // onClick={() => changeCustomerInfo({ tag: "desert" })}
                aria-labelledby="relaxation"
              >
                <img src="/img/img_relaxation.png" alt={t("page_02_1.tx_3") as string} />
                <span id="relaxation">{t("page_02_1.tx_3")}</span>
              </button>
            </Link></>
        ) : (
            <><Link to="/page-03">
                <button
                  // onClick={() => changeCustomerInfo({ tag: "desert" })}
                  aria-labelledby="relaxation"
                >
                  <img src="/img/img_relaxation.png" alt={t("page_02_1.tx_3") as string} />
                  <span id="relaxation">{t("page_02_1.tx_3")}</span>
                </button>
              </Link>
              <Link to="/epage-01">
              <button
                // onClick={() => changeCustomerInfo({ tag: "sea" })}
                aria-labelledby="education"
              >
                <img src="/img/img_education.png" alt={t("page_02_1.tx_2") as string} />
                <span id="education">{t("page_02_1.tx_2")}</span>
              </button>
            </Link></>

        )}
      </div>
    </section> 
  );
}
