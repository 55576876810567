import { useAuth } from "@/context/AuthProvider";
import { useCustomer } from "@/context/CustomerProvider";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { blogIconSVG } from "./icons";

interface MenuProps {
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export default function Menu(setMenuOpen: Dispatch<SetStateAction<boolean>>) {
  const { logoutUser } = useAuth();
  const { resetCustomerInfo } = useCustomer();
  const { t } = useTranslation();

  return (
    <div
      dir="rtl"
      id="dropdown"
      role="menu"
      className="absolute z-10 left-0 translate-y-4 w-36 bg-white rounded divide-y divide-gray-100 shadow"
      style={{position: 'absolute'}}
    >
      <ul aria-label="אפשרויות תפריט" className="py-1 text-sm text-gray-700">
        <li>
          <a
            href="https://chilax.club/%d7%91%d7%9c%d7%95%d7%92/"
            className="block py-2 px-4 hover:bg-gray-100"
            onClick={() => {}}
          >
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="w-4 h-4 ml-2 inline-block" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 113.8 122.9" style={{ enableBackground: 'new 0 0 113.8 122.9' } as React.CSSProperties} xmlSpace="preserve"><g><path d={blogIconSVG}/></g></svg>
            {t("menu.tx_3")}
          </a>
        </li>
        <li>
          <Link
            to="/page-01"
            className="block py-2 px-4 hover:bg-gray-100"
            onClick={() => setMenuOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              aria-hidden="true"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 ml-2 inline-block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            {t("menu.tx_1")}
          </Link>
        </li>
        {/*         <li>
          <Link
            onClick={resetCustomerInfo}
            to="/page-01"
            className="block py-2 px-4 hover:bg-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              aria-hidden="true"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 ml-2 inline-block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
              />
            </svg>
            אתחול
          </Link>
        </li> */}
        <li>
          <button
            onClick={logoutUser}
            className="block w-full text-right py-2 px-4 hover:bg-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              aria-hidden="true"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 ml-2 inline-block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
              />
            </svg>
            {t("menu.tx_2")}
          </button>
        </li>
      </ul>
    </div>
  );
}
