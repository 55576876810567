import { useCustomer } from "@/context/CustomerProvider";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Page04() {
  const { t } = useTranslation();
  const { changeCustomerInfo, customerInfo } = useCustomer();

  return (
    <section className="text-center" role="application">
      <h1 className="text-4xl mb-4">{t("page_4.tx_1")}</h1>
      <div className="flex flex-col gap-3">
        <button
          onClick={() => changeCustomerInfo({ experience: false })}
          className="flex gap-3 items-center"
        >
          <img
            src={`/img/radio-${
              !customerInfo.experience ? "full" : "empty"
            }.svg`}
            alt="כפתור רדיו"
          />
          <span className="text-xl">{t("page_4.tx_2")}</span>
        </button>

        <button
          onClick={() => changeCustomerInfo({ experience: true })}
          className="flex gap-3 items-center"
        >
          <img
            src={`/img/radio-${customerInfo.experience ? "full" : "empty"}.svg`}
            alt="כפתור רדיו"
          />
          <span className="text-xl">{t("page_4.tx_3")}</span>
        </button>
      </div>
    </section>
  );
}
