import { useAuth } from "@/context/AuthProvider";
import { useCustomer } from "@/context/CustomerProvider";
import { useEffect, useState } from "react";

export default function useFooterTransform(pathname: string) {
  const { customerInfo } = useCustomer();
  const { profile } = useAuth();

const transformPath = (pathname: string) => {
  const splitPath = pathname.split("-");
  const pageNumber = Number(splitPath[1]);

  let toPrevious = '';
  let toNext = '';

  if (splitPath.length === 2 && !isNaN(pageNumber)) {

    const previousNumber = pageNumber - 1;
    const nextNumber = pageNumber + 1;

    toPrevious = previousNumber >= 1
      ? (previousNumber <= 10
          ? "/page-0" + String(previousNumber)
          : "/page-" + String(previousNumber))
      : '';

    toNext = pageNumber < 10
      ? "/page-0" + String(nextNumber)
      : "/page-" + String(nextNumber);
  } else if (splitPath.length === 3) {

    const sectionSplit = splitPath[1].split("_");
    const pageSection = Number(sectionSplit[0]);
    const sectionNumber = Number(sectionSplit[1]);

    const previousSection = sectionNumber > 1
      ? (pageSection <= 10
          ? "/page-0" + String(pageSection) + "_0" + String(sectionNumber - 1)
          : "/page-" + String(pageSection) + "_" + String(sectionNumber - 1))
      : (pageSection > 1
          ? "/page-0" + String(pageSection - 1) + "_1"
          : '');

    const nextSection = pageSection < 10
      ? "/page-0" + String(pageSection) + "_0" + String(sectionNumber + 1)
      : "/page-" + String(pageSection) + "_" + String(sectionNumber + 1);

    toPrevious = previousSection !== '' ? previousSection : '';
    toNext = nextSection;
  } else if (splitPath[0] === "/epage") {
    // Handling pages like /epage-02
    const previousEPage = pageNumber >= 1
      ? "/epage-0" + String(pageNumber - 1)
      : (pageNumber === 1
          ? "/page-02_1"
          : '');
    const nextEPage = "/epage-0" + String(pageNumber + 1);

    toPrevious = previousEPage !== '' ? previousEPage : '/page-02_1';
    toNext = nextEPage;
  } else if (splitPath[1] == '02_1') {
    toPrevious = '/page-02'
  }
  
  return { toPrevious, toNext };
};

  const [hideNext, setHideNext] = useState(false);
  const [hidePrevious, setHidePrevious] = useState(false);
  const [toNext, setToNext] = useState("");
  const [toPrevious, setToPrevious] = useState("");

  //   const [result, setResult] = useState({
  //     hideNext: false,
  //     hidePrevious: false,
  //     toNext: transformPath(pathname).toNext,
  //     toPrevious: transformPath(pathname).toPrevious,
  //   });
  useEffect(() => {
    setHideNext(false);
    setHidePrevious(false);
    setToNext(transformPath(pathname).toNext || "page-01");
    setToPrevious(transformPath(pathname).toPrevious || "page-01");
    switch (pathname) {
      case "/page-01":
        setHidePrevious(true);
        break;
      case "/page-02":
        if (!profile?.educationSection){
          setToNext('/page-03')
          break;
        }
        setToNext('/page-02_1');
        break;
      case "/page-03":
        setHideNext(true);
        if (!profile?.educationSection) {
          setToPrevious('/page-02');
          break;
        }
        setToPrevious('/page-02_1');
        break;
      case "/page-05":
        if (customerInfo.experience) {
          setToNext("/page-07");
        }
        break;
      case "/page-06":
        setHideNext(true);
        break;
      case "/page-07":
        setHideNext(false);
        if (customerInfo.experience) {
          setToPrevious("/page-05");
        }
        break;
      case "/page-08":
        if (!customerInfo.experience) {
          setToPrevious("/page-06");
          setHideNext(true);
        }
        break;
      case "/page-09":
        if (customerInfo.preMood >= customerInfo.postMood) {
          setToNext("/page-11");
        } else {
          setToNext("/page-10");
        }
        break;
      case "/page-11":
        if (customerInfo.preMood >= customerInfo.postMood) {
          setToPrevious("/page-09");
        }
        if (!profile?.form) {
          setToNext("/page-13");
        }
        break;
      case "/page-12":
        setHideNext(true);
        setHidePrevious(true);
        break;
      case "/page-13":
        setHideNext(true);
        setHidePrevious(true);
        break;
      case '/epage-01':
        setHideNext(true);
        setToPrevious('/page-02_1');
        break;
      case '/page-02_1':
        setHideNext(true);
        break;
      case '/epage-02':
        setHideNext(true);
        setToPrevious('/epage-01');
        break;

      default:
        break;
    }
  }, [pathname, customerInfo]);

  return { toNext, toPrevious, hideNext, hidePrevious };
}
