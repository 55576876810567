import { useCustomer } from "@/context/CustomerProvider";
import styles from "./SliderForm.module.css";

interface SliderFormProps extends React.InputHTMLAttributes<HTMLInputElement> {
  startLabel: string;
  endLabel: string;
  mood: "pre" | "post";
}

export default function SliderForm({
  startLabel,
  endLabel,
  mood,
  ...rest
}: SliderFormProps) {
  const { changeCustomerInfo, customerInfo } = useCustomer();

  return (
    <div dir="ltr" className="w-full px-8">
      <input
        onChange={(event) =>
          changeCustomerInfo({ [mood + "Mood"]: Number(event.target.value) })
        }
        className={styles.slider}
        {...rest}
        type="range"
        value={String(customerInfo[(mood + "Mood") as keyof typeof customerInfo])}
        defaultValue={1}
        min={1}
        step={1}
        max={5}
        name="slider"
        id="slider"
      />
      <div className="flex justify-between text-gray-400 text-xl font-medium mx-2">
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
      </div>
      <div className="flex w-full justify-between">
        <span className="relative -translate-x-6 w-20">{startLabel}</span>
        <span className="relative translate-x-6 w-20">{endLabel}</span>
      </div>
    </div>
  );
}
