import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

interface LanguageSwitcherProps {
  dir: string;
}

export default function LanguageSwitcher({ dir }: LanguageSwitcherProps) {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("language"));

  useEffect(() => {
    // @ts-ignore
    localStorage.setItem("language", lang);
    // @ts-ignore
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <div
      className={`flex flex-row mt-10 ltr ml-5 ${
        dir === "ltr" ? "self-start" : "self-end"
      }`}
      onClick={(e) => setLang(lang === "en" ? "he" : "en")}
    >
      <div className="mx-2 cursor-default">🇮🇱</div>
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          value=""
          className="sr-only peer"
          checked={lang === "en"}
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-secondary peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-secondary peer-checked:bg-secondary"></div>
      </label>
      <div className="mx-2 cursor-default">🇺🇸</div>
    </div>
  );
}
