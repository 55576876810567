import useFooterTransform from "@/hooks/useFooterTransform";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import button from "@/components/Button/Button";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const buttonsRef = useRef(null);
  const { pathname } = useLocation();
  const { toPrevious, toNext, hideNext, hidePrevious } =
    useFooterTransform(pathname);
  useEffect(() => {
    // @ts-ignore
    buttonsRef.current.style.direction = i18n.dir();
  });
  return (
    <footer
      dir={i18n.dir()}
      className="flex items-center sm:flex-wrap justify-center sm:justify-between w-full h-24 sm:h-28 mx-auto my-8 gap-2 flex-col-reverse space-y-reverse sm:flex-row"
    >
      <img
        id={i18n.language == "he" ? "footerImgHe" : "footerImgEn"}
        className={pathname === "/page-01" ? "w-full max-w-xs" : "h-full"}
        src={`/img${pathname}.png`}
      />
      <div
        className={"flex " + (pathname === "/page-01" ? "order-1" : "")}
        ref={buttonsRef}
      >
        {hidePrevious ? null : (
          <Link to={toPrevious}>
            <Button style={{zIndex: 4}}>{t("common.prev")}</Button>
          </Link>
        )}
        {hideNext ? null : (
          <Link to={toNext}>
            <Button>{t("common.next")}</Button>
          </Link>
        )}
      </div>
    </footer>
  );
}
