interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

const Button = ({ className, children, ...rest }: ButtonProps) => {
  
  return (  
    <button
      id={children as string}
      className={
        "text-primary bg-transparent border-2 border-primary focus:outline-none focus:ring-4 focus:ring-primary/30 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 " +
        className
      }
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
