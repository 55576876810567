import SliderForm from "@/components/SliderForm/SliderForm";
import { useTranslation } from "react-i18next";

export default function Page09() {
  const { t } = useTranslation();
  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-4xl mb-8">{t("page_9.tx_1")}</h1>
      <div className="w-full">
        <SliderForm
          mood="post"
          startLabel={t("page_9.tx_2")}
          endLabel={t("page_9.tx_3")}
        />
      </div>
    </section>
  );
}
