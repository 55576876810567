import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import HttpApi from 'i18next-http-backend';

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'he',
        fallbackLng: 'he',
        load: 'languageOnly',
        whitelist: ['he', 'en'],
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: `/locals/{{lng}}/translation.json`
        },
        i18n: {
            defaultLocale: 'he',
            locales: ['he', 'en'],
        },
    });
export default i18n;