import {Footer} from "@/components/Footer";
import {Header} from "@/components/Header";
import {Outlet, useLocation} from "react-router-dom";
import "@fontsource/varela-round";
import ProtectedRoute from "@/components/ProtectedRoute/ProtectedRoute";
import {useTranslation} from 'react-i18next';
import {useEffect, useRef, useState} from "react";

export default function Root() {
    const {t, i18n} = useTranslation();
    const mainEl = useRef(null);
    const [refVisible, setRefVisible] = useState(false);

    useEffect(() => {
        if (!refVisible) {
            return
        }
        if (mainEl.current){
            // @ts-ignore
            mainEl.current.dir = i18n.dir();
        }
    });

    return (
        <ProtectedRoute>
            <>
                <Header/>
                <main className="flex-grow grid place-items-center" ref={el => { // @ts-ignore
                    mainEl.current = el;
                    setRefVisible(!!el);
                }}>
                    <Outlet/>
                </main>
                <Footer/>
            </>
        </ProtectedRoute>
    );
}
