import { useAuth } from "@/context/AuthProvider";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { Spinner } from "../Spinner";

interface ProtectedRouteProps extends PropsWithChildren {}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isAuth } = useAuth();
  if (isAuth === null || isAuth === undefined) {
    return <Spinner />;
  }
  if (isAuth === false) {
    return <Navigate to="/login" replace />;
  }
  return <>{children}</>;
}
