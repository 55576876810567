import { useAuth } from "@/context/AuthProvider";
import { useCustomer } from "@/context/CustomerProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiUrl } from "@/../config";

export default function Page03() {
  const { changeCustomerInfo, customerInfo } = useCustomer();
  const { profile } = useAuth();
  const { t, i18n } = useTranslation();

  const changeInfoAndUpdateStatistics = async (gender: "male" | "female") => {
    changeCustomerInfo({ gender });
    const statName = gender === "female" ? "females" : "males";
    
    const response = await fetch(
      `${apiUrl}/api/v1/statistics/` + profile?._id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ statName }),
      }
    );
    const json = await response.json();
    return json;
  };

  return (
    <section className="text-center" role="application">
      <h1 className="text-4xl mb-4">{t("page_3.tx_1")}</h1>
      <div className="flex gap-2">
        {i18n.language == "he" ? (
          <>
            <Link to="/page-04">
              <button onClick={() => changeInfoAndUpdateStatistics("female")}>
                <img
                  src="/img/female-button.png"
                  alt={t("page_3.tx_2") as string}
                />
                <span className="sr-only">{t("page_3.tx_3")}</span>
              </button>
            </Link>
            <Link to="/page-04">
              <button onClick={() => changeInfoAndUpdateStatistics("male")}>
                <img
                  src="/img/male-button.png"
                  alt={t("page_3.tx_4") as string}
                />
                <span className="sr-only">{t("page_3.tx_5")}</span>
              </button>
            </Link>{" "}
          </>
        ) : (
          <>
            <Link to="/page-04">
              <button onClick={() => changeInfoAndUpdateStatistics("male")}>
                <img
                  src="/img/male-button.png"
                  alt={t("page_3.tx_4") as string}
                />
                <span className="sr-only">{t("page_3.tx_5")}</span>
              </button>
            </Link>
            <Link to="/page-04">
              <button onClick={() => changeInfoAndUpdateStatistics("female")}>
                <img
                  src="/img/female-button.png"
                  alt={t("page_3.tx_2") as string}
                />
                <span className="sr-only">{t("page_3.tx_3")}</span>
              </button>
            </Link>{" "}
          </>
        )}
      </div>
    </section>
  );
}
