import { useAuth } from "@/context/AuthProvider";
import { useTranslation, Trans } from "react-i18next";
import { apiUrl } from "@/../config";
import { useCustomer } from "@/context/CustomerProvider";

export default function Page01() {
  const { t, i18n } = useTranslation();
  const { profile } = useAuth();
  const { changeCustomerInfo, customerInfo } = useCustomer();

  if (!customerInfo.started) {

    fetch(`${apiUrl}/api/v1/statistics/` + profile?._id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ statName: "start" }),
    });
    customerInfo.started = true
    changeCustomerInfo(customerInfo)
  }

  return (
    <section className="text-center" role="application">
      <h1 className="text-5xl sm:text-6xl mb-4">{t("page_1.tx_1")}</h1>
      <p className="text-4xl">{t("page_1.tx_2")}</p>
    </section>
  );
}
