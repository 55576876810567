import { useCustomer } from "@/context/CustomerProvider";
import { useTranslation } from "react-i18next";

export default function Page10() {
  const { t } = useTranslation();
  const { customerInfo } = useCustomer();
  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-4xl mb-4">{t("page_10.tx_1")}</h1>
      <p>{t("page_10.tx_2")}</p>
      <div className="flex justify-center relative mt-2">
        <img src="/img/steps.png" alt="" />
        <span className="text-lg absolute bottom-6 left-1/4">
          {customerInfo.preMood}
        </span>
        <span className="text-lg absolute bottom-1/2 translate-y-1/2 translate-x-4">
          {customerInfo.postMood}
        </span>
        <span className="text-lg absolute top-6 right-1/4 translate-x-4">
          ?
        </span>
      </div>
    </section>
  );
}
