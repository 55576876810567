import { createBrowserRouter, Link, Navigate } from "react-router-dom";
import Login from "./login";
import Page01 from "./pages/page-01";
import Page02 from "./pages/page-02";
import Page03 from "./pages/page-03";
import Page04 from "./pages/page-04";
import Page05 from "./pages/page-05";
import Page06 from "./pages/page-06";
import Page07 from "./pages/page-07";
import Page08 from "./pages/page-08";
import Page09 from "./pages/page-09";
import Page10 from "./pages/page-10";
import Page11 from "./pages/page-11";
import Page12 from "./pages/page-12";
import Page13 from "./pages/page-13";
import Root from "./Root";
import Page02_1 from "./pages/page-02_1/page-02_1";
import Epage01 from "./pages/epage-01/epage-01";
import Epage02 from "./pages/epage-02/epage-02";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Navigate to="/page-01" replace />
      },
      {
        path: "page-01",
        element: <Page01 />,
      },
      {
        path: "page-02",
        element: <Page02 />,
      },
      {
        path: "page-02_1",
        element: <Page02_1 />,
      },
      {
        path: "page-03",
        element: <Page03 />,
      },
      {
        path: "page-04",
        element: <Page04 />,
      },
      {
        path: "/page-05",
        element: <Page05 />,
      },
      {
        path: "page-06",
        element: <Page06 />,
      },
      {
        path: "page-07",
        element: <Page07 />,
      },
      {
        path: "page-08",
        element: <Page08 />,
      },
      {
        path: "page-09",
        element: <Page09 />
      },
      {
        path: "page-10",
        element: <Page10 />
      },
      {
        path: "page-11",
        element: <Page11 />
      },
      {
        path: "page-12",
        element: <Page12 />
      },
      {
        path: "page-13",
        element: <Page13 />
      },
      {
        path: "epage-01",
        element: <Epage01 />
      },
      {
        path: "epage-02",
        element: <Epage02 />
      }
    ],
  },
  {
    path: "login",
    element: <Login />,
    children: [
      {
        path:':username',
        element:<Login />
      }
    ]
    
  },
]);

export default router;
