import { useCustomer } from "@/context/CustomerProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetch from "@/hooks/useFetch";
import { apiUrl } from "@/../config";
import { useState, useEffect, useRef } from "react";
import { Spinner } from "@/components/Spinner";

interface VideoData {
  _id: string;
  title: string;
  description: string;
  tags: string[];
  video: string;
  lang: string;
  __v: number;
}

interface ResponseData {
  data: VideoData[];
  message: string;
}

export default function Epage02() {
  const { t, i18n } = useTranslation();
  const { customerInfo, changeCustomerInfo } = useCustomer();
  const [videos, setVideos] = useState<VideoData[] | undefined>(undefined)
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const baseStyle = document.getElementById("root")?.className;

  const handleFullscreen = (e: PointerEvent) => {
    const videoElement = e.target as HTMLVideoElement;
    videoRef.current = videoElement;
    if (!videoElement) return;
    if (!isFullscreen) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
        videoElement.muted = false;
        videoElement.play();
      }
      // @ts-ignore
      else if (videoElement.webkitEnterFullscreen) {
        // @ts-ignore
        videoElement.webkitEnterFullscreen();
        videoElement.muted = false;
        videoElement.play();
      }
      else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      // @ts-ignore
      else if (document.webkitExitFullscreen) {
        // @ts-ignore
        document.webkitExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {

      setIsFullscreen(!!document.fullscreenElement);
      const videoElement = videoRef.current;
      if (!videoElement) return;
      if (!!!document.fullscreenElement) {
        // @ts-ignore
        document.webkitExitFullscreen();
        document.exitFullscreen();
        videoElement.muted = true;
        videoElement.pause();
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // useEffect(() => {
  //   const handleScroll = async (e: Event) => {

  //     const videosNodeList = document.querySelectorAll('.video-item');

  //     videosNodeList.forEach(async (video, index) => {

  //       let top = video.getBoundingClientRect().top;
  //       let size = video.getBoundingClientRect().height;
  //       let screenSize = window.innerHeight;
  //       const currentVideo = document.getElementById(video.querySelector('video')?.id as string) as HTMLVideoElement;
  //       if(top + size < screenSize && top > 0){
  //         await currentVideo.play();
  //       }
  //       else { 
  //         await currentVideo.pause();
  //       }
  //     });

  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(()=> {
    const videosNodeList = document.querySelectorAll('.video-item');
      videosNodeList.forEach(async (video, index) => {
        const currentVideo = document.getElementById(video.querySelector('video')?.id as string) as HTMLVideoElement;
          await currentVideo.pause();
      });

  }, [videos])

  const getPlaylist = async () => {
    const { data } = await useFetch<ResponseData>(`${apiUrl}/api/v1/educations/tag/${customerInfo.educationTag}/lang/${i18n.language}`);
    const education = data?.data;
    setVideos(education);
  }
  getPlaylist();

  useEffect(() => {
    let root = document.getElementById("root") as HTMLElement;
    let classes = root.className.split(' ');
    let index = classes.indexOf('bg-blob');
    if (index !== -1) {
      classes.splice(index, 1);
    }
    let result = classes.join(' ');
    // result += ' educationPage'
    root.className = result;
    return () => {
      root.className = baseStyle as string;
    }
  }, [])

  return (
    <div className="text-center w-full max-w-sm"  >
      <div style={{position: 'absolute', width: '100%', left: '0%', zIndex: '-2', top: '160px'}} className="educationBgContainer1">
        <section style={{
          backgroundColor: 'transparent',
          backgroundImage: 'linear-gradient(101deg, #A7C399 17%, #D5F3D6 105%)',
        }}
          className="ob-is-breaking-bad elementor-section elementor-top-section elementor-element elementor-element-b09ea46 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="b09ea46" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shape_divider_bottom&quot;:&quot;waves&quot;,&quot;_ob_bbad_use_it&quot;:&quot;yes&quot;,&quot;_ob_bbad_sssic_use&quot;:&quot;no&quot;}">
          <div style={{
            backgroundImage: 'url(https://chilax.club/wp-content/uploads/2023/10/Post-BG.png)',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.15,
            transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s',
            height: '125px',
            position: 'absolute',
            width: '100%',
            left: '0%'
          }}
            className="elementor-background-overlay"></div>
          <div style={{
            overflow: 'hidden',
            position: 'relative',
            left: 0,
            width: '100%',
            lineHeight: 0,
            direction: 'ltr',
            bottom: '-2px',
            transform: 'rotate(180deg)',
            zIndex: '1',
            height: '130px'
          }} className="elementor-shape elementor-shape-bottom" data-negative="false">
            <svg style={{
              width: 'calc(269% + 1.3px)',
              height: '120px',
              transform: 'translateX(-32%) rotateY(180deg)',
            }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
              <path style={{
                fill: '#fff',
                transformOrigin: 'center',
                transform: 'rotateY(0deg)',
              }} className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
            </svg>
          </div>
        </section>
      </div>
      <h1 className="text-4xl mb-8" style={{ fontFamily: 'Varela Round', position: 'relative', top: '80px' }}>{t("epage_02.tx_1")}</h1>
      <div style={i18n.language == 'en' ? { position: 'relative', top: '130px' } : { position: 'relative', top: '130px' }}>
        {videos ? videos.map((el: VideoData, index: number) => (
          <div key={el.title} className="videoBorderContainer video-item" style={i18n.language == 'he' ? { marginBottom: '10px', left: '-15%' } : { marginBottom: '10px' }}>
            {/* @ts-ignore */}
            <video ref={videoRef} className="videoItem" id={el._id} /* controls */  autoPlay muted onClick={handleFullscreen}>
              <source src={el.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <h3 className="videoTitle">{el.title}</h3>
            <h3 className="videoDescription" style={{ margin: '5px' }}>{el.description}</h3>
          </div>
        )) : <Spinner />}

      </div>
      <div style={{ marginTop: '150px' }}></div>
    </div>
  );
}