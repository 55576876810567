import { Button } from "@/components/Button";
import { useCustomer } from "@/context/CustomerProvider";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/context/AuthProvider";
import { apiUrl } from '@/../config'

export default function Page13() {
  const { t } = useTranslation();
  const { resetCustomerInfo } = useCustomer();
  const navigate = useNavigate();
  const timerId = useRef<NodeJS.Timeout | number>(0);

  const resetCustomer = () => {
    resetCustomerInfo();
    navigate("/page-01");
  };
  const { profile } = useAuth();
  const { changeCustomerInfo, customerInfo } = useCustomer();

  if (!customerInfo.started) {

    fetch(`${apiUrl}/api/v1/statistics/` + profile?._id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ statName: "finish" }),
    });
    customerInfo.started = true
    changeCustomerInfo(customerInfo)
  }

  useEffect(() => {
    timerId.current = setTimeout(() => {
      resetCustomer();
    }, 30000);
    return () => clearTimeout(timerId.current)
  }, []);

  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-xl sm:text-2xl mb-4">{t('page_13.tx_1')}</h1>
      <Button onClick={resetCustomer}>{t('page_13.tx_2')}</Button>
    </section>
  );
}
