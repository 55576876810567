import { useTranslation } from "react-i18next";

export default function Page02() {
  const { t } = useTranslation();
  return (
    <section className="text-center" role="application">
      <h1 className="text-4xl mb-4">{t("page_2.tx_1")}</h1>
      <p className="text-xl sm:text-2xl">{t("page_2.tx_2")}</p>
      <p className="text-xl sm:text-2xl">{t("page_2.tx_3")}</p>
    </section>
  );
}
