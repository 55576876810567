import { useCustomer } from "@/context/CustomerProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Page06() {
  const { t } = useTranslation();
  const { changeCustomerInfo } = useCustomer();
  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-4xl mb-8">{t("page_6.tx_1")}</h1>
      <div className="grid grid-cols-2 max-w-xs gap-4 mx-auto">
        <Link to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "sea" })}
            aria-labelledby="sea"
          >
            <img src="/img/sea.png" alt={t("page_6.tx_6") as string} />
            <span id="sea">{t("page_6.tx_2")}</span>
          </button>
        </Link>
        <Link to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "desert" })}
            aria-labelledby="desert"
          >
            <img src="/img/desert.png" alt={t("page_6.tx_7") as string} />
            <span id="desert">{t("page_6.tx_3")}</span>
          </button>
        </Link>
        <Link to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "airbaloon" })}
            aria-labelledby="airbaloon"
          >
            <img src="/img/airbaloon.png" alt={t("page_6.tx_4") as string} />
            <span id="airbaloon">{t("page_6.tx_4")}</span>
          </button>
        </Link>
        <Link to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "ski" })}
            aria-labelledby="ski"
          >
            <img src="/img/ski.png" alt={t("page_6.tx_5") as string} />
            <span id="ski">{t("page_6.tx_5")}</span>
          </button>
        </Link>
      </div>
    </section>
  );
}
